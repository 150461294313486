import React from 'react';
import {
  isEmpty,
  Error
} from '../_components/Validation';
import Select from 'react-dropdown-select';
import {
  connect
} from 'react-redux';
import {
  Languages
} from '../_constants/functions';
import { countries } from '../_constants/countries';
import { documentActions } from '../_actions';
import Message from '../_components/Message';
import { API } from '../_services';

class ChangeNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      ifuNumber: '',
      partNumber: '',
      ifuDesc: '',
      cchangeDescription: '',
      cchangeJustification: '',
      changeReference: '',
      changeImage: '',
      changeImageName: '',
      language: '',
      online: '',
      keywords: '',
      obsoleteDate: '',
      languages: [],
      languageList: [],
      customerList: [],
      selectedCountry: [],
      associatedReferenceNumber: '',
      productSubFamilyNameOne: '',
      productSubFamilyNameTwo: '',
      options: countries,
      countryExclusionList: [],
      uploadChildDocumentFileId: '',
      childSuccess: false,
      error: false,
      success: false,
    };
  }

  componentDidMount() {
    this.setState({
      ...this.props.states,
      obsoleteDate: this.props.states && this.props.states.obsoleteDate ? new Date(this.props.states.obsoleteDate) : '',
    });

    // const languageList = [
    //   { value: 'German', name: 'German', label: 'German' },
    //   { value: 'English', name: 'English', label: 'English' },
    //   { value: 'Chinese', name: 'Chinese', label: 'Chinese' }
    // ];
    let selectedCountry = []; let selectedApprover = [];
    if (this.props.states && this.props.states.countryExclusionList) {
      let clist = this.props.states.countryExclusionList;
      let country = '';
      if (typeof (clist) === 'string') {
        country = clist.split(',');
      } else {
        country = clist;
      }

      if (country) {
        country.map((v) => {
          if (countries.find((o) => o.value === v)) {
            selectedCountry.push(countries.find((o) => o.value === v));
          }
        })
      }
    }
    // else if (this.props.states && this.props.states.countryExclusionList && Array.isArray(this.props.states.countryExclusionList)) {
    //   // selectedCountry = this.props.states.countryExclusionList;
    //   let country = this.props.states.countryExclusionList;
    //   if (country) {
    //     country.map((v) => {
    //       if (countries.find((o) => o.value === v)) {
    //         selectedCountry.push(countries.find((o) => o.value === v));
    //       }
    //     })
    //   }
    // }

    let langList = [];
    if (this.props.states && this.props.states.language && !Array.isArray(this.props.states.language)) {
      let clist = this.props.states.language;
      let lang = clist.split(',');
      if (lang) {
        lang.map((v) => {
          let chk = Languages.find((o) => o.value === v)
          if (chk) {
            langList.push(chk);
          }
        })
      }
    } else if (this.props.states && this.props.states.language && Array.isArray(this.props.states.language)) {
      // selectedCountry = this.props.states.countryExclusionList;
      let lang = this.props.states.language;
      if (lang) {
        lang.map((v) => {
          let chk = Languages.find((o) => o.value === v)
          if (chk) {
            langList.push(chk);
          }
        })
      }
    }

    // let languages = [];
    // if (this.props.states && this.props.states.language) {
    //   let language = this.props.states.language;
    //   let value = Languages.find((o) => o.value === language);
    //   if (value) languages.push(value);
    // }
    // console.log(langList)
    // console.log(selectedCountry)
    this.setState({
      languages: langList,
      languageList: Languages,
      selectedCountry: selectedCountry,
    })
    // console.log(this.props.states);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.uploadChildDocumentFileId !== this.props.uploadChildDocumentFileId) {
      // this.setState({ uploadChildDocumentFileId: this.props.uploadChildDocumentFileId, childSuccess: true });
    }

    // if (this.props.documents.message && this.props.documents.message != "Empty") {
    //   // this.setState({ message: this.props.documents.message });
    //   // setTimeout(() => {
    //   //   this.setState({ message: null, error: null });
    //   //   this.props.history.push('/document/list');
    //   // }, 4000);
    //   console.log('jhsdjfhjgs')
    // }
    // console.log(this.props.documents)
  }

  changeInput = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  imageUpload = e => {
    this.refs.fileUploader.click();
  }

  imageChange = event => {
    if (event.target.files.length > 0) {
      let files = [];
      let file = event.target.files[0];
      files.push(event.target.files[0]);
      // this.props.addDocumentFiles(files, false);
      API.addDocumentFiles(files, false).then(resp => {
        if (resp.status === 200) {
          this.setState({
            changeImage: file,
            changeImageName: file.name,
            success: 'Document Uploaded',
            uploadChildDocumentFileId: resp.data.uploadChildDocumentFileId,
          });
          setTimeout(() => {
            this.setState({ success: null });
          }, 2000);
        } else {
          this.setState({ error: resp.data.message });
          setTimeout(() => {
            this.setState({ error: null });
          }, 4000);
        }
      });
    } else {
      this.setState({
        changeImage: null,
        changeImageName: null
      });
    }
  }

  onChange = (date, type) => {
    this.setState({
      [type]: date
    });
  }

  changeCheckbox = event => {
    this.setState({
      [event.target.name]: event.target.id
    });
  }

  validation = e => {
    const {
      associatedReferenceNumber,
      ifuNumber,
      partNumber,
      ifuDesc,
      cchangeDescription,
      cchangeJustification,
      changeReference,
      changeImage,
      changeImageName,
      keywords,
      language,
      online,
      countryExclusionList,
      approvedDate,
      obsoleteDate,
      productSubFamilyNameOne,
      productSubFamilyNameTwo,
    } = this.state;

    let errors = {};
    if (!associatedReferenceNumber) errors['ifu'] = 'Enter IFU Number';
    if (!productSubFamilyNameOne) errors['part'] = 'Enter Part Number';
    if (!productSubFamilyNameTwo) errors['bill'] = 'Enter Bill of Material';
    if (!cchangeDescription) errors['desc'] = 'Enter Change Description';
    if (!cchangeJustification) errors['just'] = 'Enter Justification';
    if (countryExclusionList.length === 0) errors['country'] = 'Select Country';
    if (!changeImage) errors['image'] = 'Select a document';
    if (!keywords) errors['keywords'] = 'Enter Keywords';
    if (!language) errors['language'] = 'Select Language';
    // if (!online) errors['online'] = 'Select Online';
    // if (!obsoleteDate) errors['obsolete'] = 'Select Obsolete Date';
    this.setState({
      errors: errors
    });
    return errors;
  }

  handleSubmit = e => {
    e.preventDefault();
    if (isEmpty(this.validation())) {
      this.props.data({
        ...this.state,
        errors: {}
      });
      this.props.next(true);
    }
  }

  back = e => {
    this.props.data({
      ...this.state,
      errors: {}
    });
    this.props.back();
  }

  onMultiSelect = (selectedList, name) => {
    let list = selectedList.map((country) => {
      return country.value;
    });

    this.setState({
      [name]: list
    });
  }

  render() {
    let {
      errors,
      ifuNumber,
      partNumber,
      ifuDesc,
      cchangeDescription,
      cchangeJustification,
      changeReference,
      changeImage,
      changeImageName,
      keywords,
      languages,
      online,
      obsoleteDate,
      languageList,
      customerList,
      customers,
      productSubFamilyNameOne,
      productSubFamilyNameTwo,
      associatedReferenceNumber,
      selectedCountry,
      error,
      success,
    } = this.state;

    return (
      <React.Fragment>
        <div>
          <div className="page-header">
            <h3 className="page-title">
              <span className="page-title-icon bg-gradient-primary text-white mr-2">
                <i className="mdi mdi-plus-circle" />
              </span>
              Change/New
            </h3>
          </div>
          <div className="row">
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>IFU Number</label>
                          <input type="text" name='associatedReferenceNumber'
                            value={associatedReferenceNumber}
                            onChange={this.changeInput}
                            className="form-control"
                            placeholder={'Enter IFU Number'} />
                          <Error error={errors['ifu']} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Part Number</label>
                          <input type="text" name='productSubFamilyNameOne' value={productSubFamilyNameOne} onChange={this.changeInput} className="form-control" placeholder={'Enter Part Number'} />
                          <Error error={errors['part']} />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Bill of Material</label>
                          <input type="text" name='productSubFamilyNameTwo' value={productSubFamilyNameTwo} onChange={this.changeInput} className="form-control" placeholder={'Enter Bill of Material'} />
                          <Error error={errors['bill']} />
                        </div>
                      </div>
                      <div className="col-md-6" style={{ marginTop: '12px' }}>
                        <div className="form-group">
                          <label>Country</label>
                          <Select
                            multi
                            className="form-control"
                            options={this.state.options}
                            values={this.state.selectedCountry}
                            onChange={(values) => this.onMultiSelect(values, 'countryExclusionList')}
                          />
                          <Error error={errors['country']} />
                        </div>
                      </div>
                      <div className="col-md-6" style={{ marginTop: '12px' }}>
                        <div className="form-group">
                          <label>Language</label>
                          <Select
                            multi
                            className="form-control"
                            options={languageList}
                            values={languages}
                            onChange={(value) => this.onMultiSelect(value, 'language')}
                          />
                          <Error error={errors['language']} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>keywords</label>
                          <input type="text" name='keywords' value={keywords} onChange={this.changeInput} className="form-control" />
                          <Error error={errors['keywords']} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Description of Change</label>
                          <textarea rows={4} name='cchangeDescription' value={cchangeDescription} onChange={this.changeInput} className="form-control" />
                          <Error error={errors['desc']} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Justification of Change</label>
                          <textarea rows={4} name='cchangeJustification' value={cchangeJustification} onChange={this.changeInput} className="form-control" />
                          <Error error={errors['just']} />
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                        <div className="form-group">
                          <label>Reference of Change</label>
                          <textarea rows={4} name='changeReference' value={changeReference} onChange={this.changeInput} className="form-control" />
                          <Error error={errors['ref']} />
                        </div>
                      </div> */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Upload doc {changeImage ? <i className="mdi mdi-checkbox-marked-circle" style={{ color: 'green' }} /> : null}</label>
                          <div className="input-group">
                            <input type="text" className="form-control" value={changeImage ? changeImageName : 'No Image Selected'}
                              aria-label="Recipient's username" disabled aria-describedby="basic-addon2" id='BtnBrowseHidden' />
                            <input type="file" ref="fileUploader" onChange={this.imageChange} style={{ display: 'none' }} />
                            <div className="input-group-append">
                              <button className={this.state.childSuccess || this.props.childImg ? "btn btn-sm btn-gradient-secondary" : "btn btn-sm btn-gradient-primary"} onClick={this.state.childSuccess || this.props.childImg ? null : this.imageUpload} type="button">Upload File</button>
                            </div>
                          </div>
                          <Error error={errors['image']} />
                        </div>
                      </div>


                      {/* <div className="col-md-12">
                        <div className="form-group">
                          <label>Effective Date</label>
                          <DatePicker
                            className="form-control"
                            name='effectiveDate'
                            onChange={(e) => this.onChange(e, 'effectiveDate')}
                            value={effectiveDate}
                            format='dd/MM/y'
                          />
                          <Error error={errors['effective']} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>Obsolete Date</label>
                          <DatePicker
                            className="form-control"
                            name='obsoleteDate'
                            onChange={(e) => this.onChange(e, 'obsoleteDate')}
                            value={obsoleteDate}
                            format='dd/MM/y'
                          />
                          <Error error={errors['obsolete']} />
                        </div>
                      </div> */}

                      {/* <div className="col-12">
                        <div className="row d-flex align-items-center">
                          <div className="col-md-3 col-lg-3">
                            Specific Change Type
                                                    </div>
                          <div className="col-md-9 col-lg-9">
                            <div className="form-check d-inline-block mr-4">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="changeType" id="manual" checked={changeType === 'manual' ? true : false} onChange={this.changeCheckbox} /> Manual <i className="input-helper" /><i className="input-helper" /></label>
                            </div>
                            <div className="form-check d-inline-block mr-4">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="changeType" id="label" checked={changeType === 'label' ? true : false} onChange={this.changeCheckbox} /> Label <i className="input-helper" /><i className="input-helper" /></label>
                            </div>
                          </div>
                          <Error error={errors['type']} />
                        </div>
                      </div> */}

                      {/* <div className="col-12">
                        <div className="row d-flex align-items-center">
                          <div className="col-md-3 col-lg-3">
                            Languages
                                                    </div>
                          <div className="col-md-9 col-lg-9">
                            <div className="form-check d-inline-block mr-4">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="language" id="german" checked={language === 'german' ? true : false} onChange={this.changeCheckbox} /> German <i className="input-helper" /><i className="input-helper" /></label>
                            </div>
                            <div className="form-check d-inline-block mr-4">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="language" id="chinese" checked={language === 'chinese' ? true : false} onChange={this.changeCheckbox} /> Chinese <i className="input-helper" /><i className="input-helper" /></label>
                            </div>
                            <div className="form-check d-inline-block mr-4">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="language" id="english" checked={language === 'english' ? true : false} onChange={this.changeCheckbox} />   English <i className="input-helper" /><i className="input-helper" /></label>
                            </div>
                          </div>
                          <Error error={errors['language']} />
                        </div>
                      </div> */}

                      {/* <div className="col-12">
                        <div className="row d-flex align-items-center">
                          <div className="col-md-3 col-lg-3">
                            Make Available online?
                                                    </div>
                          <div className="col-md-9 col-lg-9">
                            <div className="form-check d-inline-block mr-4">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="online" id="yes" checked={online === 'yes' ? true : false} onChange={this.changeCheckbox} /> Yes <i className="input-helper" /><i className="input-helper" /></label>
                            </div>
                            <div className="form-check d-inline-block mr-4">
                              <label className="form-check-label">
                                <input type="radio" className="form-check-input" name="online" id="no" checked={online === 'no' ? true : false} onChange={this.changeCheckbox} />   No <i className="input-helper" /><i className="input-helper" /></label>
                            </div>
                          </div>
                          <Error error={errors['online']} />
                        </div>
                      </div> */}
                    </div>
                  </form></div>
                <div className="form-group text-center">
                  <div className="row d-flex align-items-center mt-5">
                    <div className="col-md-12 text-center">
                      <span onClick={this.back} className="btn btn-gradient-secondary" style={{ marginRight: '10px' }}>Back</span>
                      <span onClick={this.handleSubmit} className="btn btn-gradient-primary ">Next</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Message error={error} success={success} />

      </React.Fragment >

    );
  }
}

const mapStateToProps = state => ({
  customerList: state.customers,
  uploadChildDocumentFileId: state.documents.uploadResponse && state.documents.uploadResponse.uploadChildDocumentFileId,
  documents: state
});

const actionCreators = {
  addDocumentFiles: documentActions.addDocumentFiles,
}

export default connect(mapStateToProps, actionCreators)(ChangeNew);